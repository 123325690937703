import React, { Component } from 'react';
import { Row, Col, Typography, Form, Input, Button, notification, Checkbox, Avatar, Result } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Redirect, Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Core from "../../Core";
import styles from './index.scss';

const { Title } = Typography;

export default class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      formSuccess: false,
      redirectLogin: false
    };
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  onFinish(values) {
    const fail = (extraInfo = '') => {
      const description = `Erro ao efetuar registo. ${extraInfo}`
      console.log(extraInfo);
      notification.error({
        message: 'Registo',
        description: <div dangerouslySetInnerHTML={{ __html: description }}></div>,
        top: 200
      });

      ReactGA.event({
        category: Core.data().config.site_title,
        action: `Register`,
        label: `Unsuccessful`
      });

      this.setState({ loading: false, error: true });
    };
    this.setState({ loading: true });
    Core.apiService(`register`, {
      data: {
        name: values.name,
        mail: values.mail.toLowerCase(),
        metadata: {
          dealer: values.dealer,
          //  specialty: values.specialty,
        }
      },
      onSuccess: (data) => {
        if (data.json && data.json['result'] == true) {
          this.setState({ loading: false, formSuccess: true });
          notification.success({
            message: 'Sucesso',
            description: 'Registo efetuado com sucesso.'
          });
          setTimeout(function () {
            this.setState({ redirectLogin: true });
          }.bind(this), 2000);

          ReactGA.event({
            category: Core.data().config.site_title,
            action: `Register`,
            label: `${data.json.name}`
          });
        } else if (data.json && data.json['result'] == false) {
          if (data.json['already_exist']) {
            fail(`O email já se encontra registado. Por favor, faça <a href="/login">login</a>.`);
          } else {
            fail(data.json['msg']);
          }
        } else {
          console.log("Register data is not a JSON!");
          fail();
        }
      },
      onFail: (data) => {
        this.setState({ loadingTable: true });
        console.log("Register data failed.", data);
        fail();
      }
    });
  };

  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  render() {
    const { redirectLogin, loading, formSuccess } = this.state;

    if (redirectLogin) {
      return <Redirect to="/" />;
    }

    const layout = null;/*{
            labelCol: { xl: { span: 10 }, lg: { span: 12 }, md: { span: 14 }, sm: { span: 16 } },
            wrapperCol: { xl: { span: 10 }, lg: { span: 12 }, md: { span: 14 }, sm: { span: 16 } },
        };*/
    const tailLayout = {
      wrapperCol: {
        xl: { span: 10, offset: 7 }, lg: { offset: 6, span: 12 }, md: { span: 14, offset: 5 }, sm: { offset: 4, span: 16 }
      },
    };
    return (
      <div className="register">
        <Row>
          <Col xl={{ span: 10, offset: 7 }} lg={{ span: 12, offset: 6 }} md={{ span: 14, offset: 5 }} sm={{ span: 16, offset: 4 }}>
            <div className="content-title">
              <div className="image"></div>
              <hr />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 12, offset: 6 }} lg={{ span: 18, offset: 3 }} md={{ span: 20, offset: 2 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
            {(formSuccess ?
              <Result
                status="success"
                title="Obrigado pelo seu registo. Irá receber um e-mail de confirmação"
                subTitle="Brevemente será redirecionado para a homepage."
              />
              :
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  className="center-input"
                  name="name"
                  rules={[{ required: true, message: 'Insira o seu Nome.' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="o seu nome e apelido." />
                </Form.Item>
                <Form.Item
                  className="center-input"
                  name="mail"
                  rules={[{ required: true, message: 'Insira um Email válido.', pattern: new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,8}$") }]}
                >
                  <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="o seu endereço de mail." />
                </Form.Item>
                <Form.Item className="register__checkbox"
                  className="center-input"
                  name="checkbox"
                  valuePropName="checked"
                  rules={[{
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject('É necessário aceitar!'),
                  }]}
                >
                  <Checkbox>
                    <b>Autorizo</b> <br /> Autorizo o tratamento dos meus dados pessoais pela You Are Live, em conformidade com a legislação em vigor, os termos e condições aplicáveis e a nossa politica de privacidade, de acordo com a natureza, âmbito, finalidade e objeto dos serviços por nós disponibilizados e aos quais aderi.
                      </Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout} className="register__form-action">
                  <Button className="submit-button" type="primary" htmlType="submit" loading={loading}>
                    Registar
                      </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 10, offset: 7 }} lg={{ span: 12, offset: 6 }} md={{ span: 14, offset: 5 }} sm={{ span: 16, offset: 4 }}>
            <hr />
          </Col>
        </Row>
      </div>

    );
  }
}
