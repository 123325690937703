import React, { Component } from 'react';
import { Typography } from 'antd';

import Schedule from "../../containers/Schedule/index.jsx";

import './index.scss';

const { Title } = Typography;

export default class SchedulePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="schedule-page">
                <div className="content-title">
                    <Title className="schedule__title">Agenda</Title>
                    </div>
                <Schedule />
            </div>

        );
    }
}
