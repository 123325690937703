import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Typography, Spin, Form, Input, Checkbox, Button, notification } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import sha512 from 'js-sha512';

import Core from '../../Core';
import RecoverModal from './RecoverModal/index.jsx';
import styles from "./index.scss";

const { Title } = Typography;

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            counter: 0,
            loginOutput: null,
            error: false,
            visibleModal: false
        };
        this.login = this.login.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (this.props.token) {
            this.login(this.props.token);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleClick(event) {
        this.setState({
            visibleModal: !this.state.visibleModal
        });
    }

    login(hash, values) {
        this.setState({loading: true});
        const fail = () => {
            this.setState({loading: false});
            notification.error({
                message: 'Login',
                description: 'Os dados de acesso são inválidos.',
                top: 200
            });
            ReactGA.event({
              category: Core.data().config.site_title,
              action: `Login`,
              label: `Unsuccessful`
            });
        };
        Core.apiData(`login/${hash}.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    Core.data().hash = hash;
                    Core.data().arena = data.json.arena;
                    Core.data().login = data.json;
                    sessionStorage.setItem("data", JSON.stringify(Core.data()));
                    const { onLoggedIn } = this.props;
                    if (onLoggedIn) {
                        onLoggedIn(Core.data());
                    }
                    this.setState({loading: false});

                    ReactGA.event({
                      category: Core.data().config.site_title,
                      action: `Login`,
                      label: `${Core.data().login.name} (${Core.data().login.hid.substring(0,3)})`
                    });

                } else {
                    //this.setState({ error: true });
                    //console.log("Login data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                //this.setState({ error: true });
                //console.log("Login data failed.", data);
                if (Core.data().config.email_only) {
                    Core.apiService(`register`, {
                        data: {
                            mail: values.mail.toLowerCase()
                        },
                        onSuccess: (data) => {
                            if (data.json) {
                                Core.apiData(`login/${hash}.json`, {
                                    onSuccess: (data) => {
                                        if (data.json) {
                                            Core.data().hash = hash;
                                            Core.data().arena = data.json.arena;
                                            Core.data().login = data.json;
                                            sessionStorage.setItem("data", JSON.stringify(Core.data()));
                                            const { onLoggedIn } = this.props;
                                            if (onLoggedIn) {
                                                onLoggedIn(Core.data());
                                            }
                                        } else {
                                            fail();
                                        }
                                    }
                                });
                            } else {
                                console.log("Register data is not a JSON!");
                                fail();
                            }
                        },
                        onFail: (data) => {
                            console.log("Register data failed.", data);
                            fail();
                        }
                    });
                } else {
                    fail();
                }
            }
        });
    }

    onFinish(values) {
        const hash = sha512(Core.data().config.no_password || Core.data().config.email_only ? values.mail.toLowerCase() : values.mail.toLowerCase() + ':' + values.password);
        if (values.remember) {
            sessionStorage.setItem('mail', values.mail.toLowerCase());
            sessionStorage.setItem('password', values.password);
        }
        this.login(hash, values);
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    render() {
        const { loading, counter, visibleModal } = this.state;
        if (loading) {
            return (<div className="login"><Spin/></div>);
        }
        const layout = {
            labelCol: { xl: { span: 10 }, lg: { span: 12 }, md: { span: 14 }, sm: { span: 16 } },
            wrapperCol: { xl: { span: 10 }, lg: { span: 12 }, md: { span: 14 }, sm: { span: 16 } },
        };
        const tailLayout = {
            wrapperCol: {
                xl: { span: 10, offset: 7 }, lg: { offset: 6, span: 12 }, md: { span: 14, offset: 5 }, sm: { offset: 4, span: 16 }
            },
        };

        return (
            <div className="login">
              <Row>
                <Col className="login--image" xl={{ span: 10, offset: 7 }} lg={{ span: 12, offset: 6 }} md={{ span: 14, offset: 5 }} sm={{ span: 16, offset: 4 }}>
                    <img alt="logo-login" className="logo-login" src="/data/images/logo-alt.png" />
                </Col>
              </Row>
              <Form
                {...layout}
                name="basic"
                initialValues={{
                    mail: sessionStorage.getItem("mail"),
                    password: sessionStorage.getItem("password"),
                    remember: true
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  className="center-input"
                  name="mail"
                  rules={[{ required: true, type: 'email', message: 'Por favor insira o seu endereço de email.' }]}
                >
                  <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="E-mail" />
                </Form.Item>
                <Form.Item
                  className="center-input"
                  name="password"
                  rules={[{ required: (!Core.data().config.no_password) && (!Core.data().config.email_only), message: 'Por favor insira a palavra-passe que recebeu por mail.' }]}
                  style={{ display: Core.data().config.no_password || Core.data().config.email_only ? 'none' : 'block' }}
                >
                  <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
                </Form.Item>

                <Form.Item {...tailLayout} className="login__form-action">
                  <Button className="submit-button" type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>

                <Form.Item {...tailLayout} className="login__form-action" name="remember" valuePropName="checked">
                  <Checkbox>Guardar os dados para acesso futuro</Checkbox>
                  <a className="right-input" onClick={this.handleClick} style={{ display: Core.data().config.no_password || Core.data().config.email_only ? 'none' : 'block' }}>Recuperar Password</a>
                  {visibleModal ? <RecoverModal onClose={() => {
                      this.setState({ visibleModal: false });
                  }} /> : null}
                </Form.Item>

                <Form.Item {...tailLayout}
                  className="register__form-action"
                  style={{display: Core.data().config.register ? 'block' : 'none'}}>
                      <hr></hr>
                      <Button className="register-button" type="primary" htmlType="submit">
                        <a href="./registo">Registar</a>
                      </Button>
                  </Form.Item>
              </Form>
              <Row>
                  <Col xl={{ span: 10, offset: 7 }} lg={{ span: 12, offset: 6 }} md={{ span: 14, offset: 5 }} sm={{ span: 16, offset: 4 }}>
                      <hr />
                  </Col>
              </Row>
            </div>

        );

    }
}
